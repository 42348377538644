import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <p>{`As investors, we often consider ourselves small fish in the infinitely big sea of financial markets. And being such a small fish, there should be plenty of market liquidity to act as a counterparty to our trades. But how much capacity do the financial markets really have, and when do we become a relevant player, able to move the markets? Let's find out.`}</p>
    <p>{`We collected the data shown here in November 2021. Even though the various market indices' constituents and the exact numbers change frequently, we believe that these data should still provide a good reference for the future.`}</p>
    <h2>{`Liquidity in the Stock Market`}</h2>
    <h3>{`Large-Cap Stocks`}</h3>
    <p>{`Large-cap stocks are shares of companies with a market capitalization of `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/l/large-cap.asp"
      }}>{`more than $10 billion`}</a>{`. We see these companies represented by the following indices with their respective average daily dollar volume:`}</p>
    <ul>
      <li parentName="ul">{`S&P 100: $195M - $18B`}</li>
      <li parentName="ul">{`Dow Jones Industrial Average: $186M - $12B`}</li>
      <li parentName="ul">{`Nasdaq-100: $40M - $18B`}</li>
      <li parentName="ul">{`S&P 500: $13M - $18B`}</li>
    </ul>
    <p>{`Most individual investors will find sufficient liquidity in the constituents of these indices to accommodate their trading style. However, we already notice significantly reduced liquidity at the bottom of the S&P 500.`}</p>
    <h4>{`S&P 100 ($OEX), S&P 500 ($SPX)`}</h4>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.slickcharts.com/sp500"
          }}>{`S&P 500 constituents`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-a-stroll/"
          }}>{`Stocks on a Stroll`}</a></li>
      </ul>
    </Aside>
    <p>{`The `}<em parentName="p">{`S&P 100`}</em>{` represents the 100 largest corporations traded at U.S. exchanges, often called `}<em parentName="p">{`blue-chip`}</em>{` companies. We notice a wide gap in trading volumes, with the largest company trading roughly 100 times the dollar amounts of the company at rank 100.`}</p>
    <p>{`Similarly, the `}<em parentName="p">{`S&P 500`}</em>{` tracks the 500 largest corporations traded at U.S. exchanges. By definition, the top-ranking stocks are identical to the `}<em parentName="p">{`S&P 100`}</em>{`. However, we have 400 smaller companies following at the bottom:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rank`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TSLA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tesla Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$18,771,104,051`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AAPL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$12,091,332,969`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AMZN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amazon.com Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$11,398,315,525`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MSFT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Microsoft Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$6,714,959,545`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Meta Platforms Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$5,898,681,960`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EMR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Emerson Electric Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$238,988,700`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`97`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EXC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exelon Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$231,182,861`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AIG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`American International Group Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$216,010,139`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KHC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kraft Heinz Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$208,027,923`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General Dynamics Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$195,235,210`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`496`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Loews Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$44,671,657`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`497`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FOX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fox Corp Class B Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$40,176,005`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`498`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LEG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leggett & Platt Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$39,201,085`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`499`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Globe Life Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$38,873,848`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NWS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`News Corp Class B Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$13,193,191`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`We notice that within the `}<em parentName="p">{`S&P 100`}</em>{`, the trading volume declines sharply, with the most-liquid company trading roughly 100x times the dollar amounts of the company at rank 100. However, this decline slows down over the following 400 stocks, and the least-liquid company in the `}<em parentName="p">{`S&P 500`}</em>{` still trades at about 1/10`}<sup>{`th`}</sup>{` the volume of the company in position 100.`}</p>
    <h4>{`Dow Jones Industrial Average ($DJI)`}</h4>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.slickcharts.com/dowjones"
          }}>{`Dow Jones constituents`}</a></li>
      </ul>
    </Aside>
    <p>{`The `}<em parentName="p">{`Dow Jones Industrial Average`}</em>{` index is a price-weighted index of `}<a parentName="p" {...{
        "href": "https://www.spglobal.com/spdji/en/indices/equity/dow-jones-industrial-average/#overview"
      }}>{`30 hand-picked blue-chip companies`}</a>{`. It is historically industry-heavy, even though it is supposed to represent all U.S. companies. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rank`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AAPL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$12,091,332,969`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MSFT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Microsoft Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$6,714,959,545`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boeing Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$2,954,699,959`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JPM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JPMorgan Chase & Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,932,143,754`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`V`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visa Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,761,938,242`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AXP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`American Express Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$548,317,207`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3M Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$457,227,346`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WBA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Walgreens Boots Alliance Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$288,771,230`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DOW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dow Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$283,550,512`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TRV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Travelers Companies Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$186,139,685`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The liquidity of its constituents falls into a very similar range as the `}<em parentName="p">{`S&P 100`}</em>{`. This should not come as a surprise, given these are all blue-chip companies. However, the somewhat intransparent composition of the index makes it a somewhat arbitrary universe for trading strategies.`}</p>
    <h4>{`Nasdaq-100 ($NDX)`}</h4>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.slickcharts.com/nasdaq100"
          }}>{`Nasdaq-100 constituents`}</a></li>
      </ul>
    </Aside>
    <p>{`The `}<em parentName="p">{`Nasdaq-100`}</em>{` includes the 100 largest corporations traded at the `}<a parentName="p" {...{
        "href": "https://www.nasdaq.com/"
      }}>{`Nasdaq exchange`}</a>{`. In the current economic environment, the `}<em parentName="p">{`Nasdaq`}</em>{`'s top companies also lead the `}<em parentName="p">{`S&P 500`}</em>{`. However, this may easily change in the future.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rank`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TSLA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tesla Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$18,771,104,051`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AAPL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$12,091,332,969`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AMZN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amazon.com Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$11,398,315,525`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MSFT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Microsoft Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$6,714,959,545`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Meta Platforms Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$5,898,681,960`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FOXA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fox Corp Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$121,221,724`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`97`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHKP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Check Point Software Technologies Ltd Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$120,899,522`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VRSN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verisign Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$112,276,361`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INCY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Incyte Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$105,619,207`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FOX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fox Corp Class B Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$40,176,005`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`It is worth noting that the `}<em parentName="p">{`Nasdaq-100'_s least-liquid companies trade at about three times the volume of the bottom-ranked _S&P 500`}</em>{` companies. Because the `}<a parentName="p" {...{
        "href": "https://guides.loc.gov/wall-street-history/exchanges"
      }}>{`Nasdaq was the world's first electronic stock market`}</a>{`, its constituents historically had higher trading volumes than those listed on other exchanges. However, further research would be required to see if this is still the case.`}</p>
    <h3>{`Mid-Cap Stocks`}</h3>
    <p>{`Mid-cap companies have a market capitalization between $2B and $10B. We see these companies represented by the following indices:`}</p>
    <ul>
      <li parentName="ul">{`S&P MidCap 400: $7.5M - $1.2B`}</li>
      <li parentName="ul">{`Russell Midcap: $3M - $1.5B`}</li>
    </ul>
    <p>{`With the liquidity of these stocks reaching single-digit millions per day, these stocks will probably no longer appeal to all investors. Especially investors looking to trade frequently might see the performance of their strategies deviate from backtested results due to higher than expected slippage.`}</p>
    <h4>{`S&P MidCap 400 ($MID), Russell 1000 ($RUI), Russell Midcap ($RMC)`}</h4>
    <p>{`The `}<em parentName="p">{`S&P MidCap 400`}</em>{` index is the most widely followed mid-cap index. Its constituents are the 400 companies following after the `}<em parentName="p">{`S&P 500`}</em>{`. To be included, companies must have a `}<a parentName="p" {...{
        "href": "https://www.spglobal.com/spdji/en/indices/equity/sp-400/#overview"
      }}>{`market capitalization between $3.6B and $13.1B`}</a>{`. However, the more simplistic view of the index is that it covers stocks listed on U.S. exchanges that rank from 501 to 900 by market capitalization.`}</p>
    <p>{`The `}<em parentName="p">{`Russell Midcap`}</em>{` index tracks the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Russell_Midcap_Index"
      }}>{`800 smallest companies within the Russell 1000`}</a>{`. Because the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Russell_1000_Index"
      }}>{`Russell 1000 tracks the 1,000 largest stocks traded on U.S. exchanges`}</a>{`, this mid-cap index covers ranks 200 to 1,000 by market capitalization.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rank`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`201`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PLTR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Palantir Technologies Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,545,460,418`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`202`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ROKU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Roku Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,462,353,291`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`203`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DASH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DoorDash Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,248,001,156`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GME`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GameStop Corp Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,237,451,692`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`205`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PTON`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Peloton Interactive Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$984,077,915`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`501`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GME`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GameStop Corp Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,237,452,955`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`United States Steel Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$461,545,617`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CLF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cleveland-Cliffs Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$435,742,190`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`504`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RH Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$294,507,616`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`505`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RUN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sunrun Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$291,450,381`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`896`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NTCT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Netscout Systems Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$10,365,592`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`897`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MTX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minerals Technologies Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$9,187,798`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`898`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GEF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Greif Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$8,898,190`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`899`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IBOC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`International Bancshares Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$8,607,465`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`900`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tootsie Roll Industries Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$7,527,300`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`996`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VIACA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ViacomCBS Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$5,039,291`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`997`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NABL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N-Able Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$4,426,694`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`998`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TFSL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TFS Financial Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$3,980,511`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`999`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SEB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seaboard Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$3,266,232`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF.A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brown-Forman Corp Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$3,132,369`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`It is worth noting that the traded volume at the top of the `}<em parentName="p">{`S&P MidCap 400`}</em>{` is at least ten times higher than at the bottom of the `}<em parentName="p">{`S&P 500`}</em>{` (if excluding GameStop as an outlier). We currently have no explanation for this somewhat surprising result. For once, it might be related to the inclusion rules for the indices. Possibly, these rules prevent some stocks from being included in the `}<em parentName="p">{`S&P 500`}</em>{`, even though they have outgrown their mid-cap classification. Another explanation would be the popularity of investing in mid-cap and small-cap stocks in recent years, primarily through ETFs.`}</p>
    <h3>{`Small-Cap Stocks`}</h3>
    <p>{`Small-cap companies have a market capitalization between $300M and $2B. The following indices represent these companies:`}</p>
    <ul>
      <li parentName="ul">{`S&P SmallCap 600: $898k - $324M`}</li>
      <li parentName="ul">{`Russell 2000: $190,000 - $468M`}</li>
    </ul>
    <p>{`With liquidity dipping below $1M per day, these stocks are likely not suitable for any active trading approach. This does not mean they aren't investable, but because of slippage associated with these low volumes, investors in these assets should follow strategies with rather long time horizons.`}</p>
    <h4>{`S&P SmallCap 600 ($SML), Russell 2000 ($RUT)`}</h4>
    <p>{`The `}<em parentName="p">{`S&P SmallCap 600`}</em>{` seeks to measure the small-cap segment of the U.S. equity market. To be included, companies must meet specific criteria to ensure they are liquid and financially viable. One of these criteria is that companies must have a `}<a parentName="p" {...{
        "href": "https://www.spglobal.com/spdji/en/indices/equity/sp-600/#overview"
      }}>{`market capitalization between $850 million and $3.6 billion`}</a>{`. This comes close to an index including companies ranking between 900 and 1,500 when ordered by their market capitalization.`}</p>
    <p>{`The `}<em parentName="p">{`Russell 2000`}</em>{` is another small-cap index, which tracks the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Russell_2000_Index"
      }}>{`2,000 smallest stocks in the Russell 3000 index`}</a>{`. This equates to the ranks 1,001 to 3,000 of U.S. stocks by market capitalization.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rank`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`901`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Macy's Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$324,041,484`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`902`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BBBY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bed Bath & Beyond Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$170,912,104`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`903`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DDD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3D Systems Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$125,162,665`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`904`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CNK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cinemark Holdings Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$79,241,169`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`905`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RRC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Range Resources Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$78,213,064`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AMC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AMC Entertainment Holdings Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$2,893,601,863`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,002`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RIOT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Riot Blockchain Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$468,767,460`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,003`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DNUT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Krispy Kreme Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$451,362,945`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,004`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MARA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marathon Digital Holdings Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$435,969,208`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,005`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MSTR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Microstrategy Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$415,586,887`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,496`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SENEA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seneca Foods Corp Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,546,799`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,497`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FRGI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fiesta Restaurant Group Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,322,453`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,498`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PKE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Park Aerospace Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$1,265,664`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,499`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DAKT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Daktronics Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$899,781`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1,500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BMTX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BM Technologies Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$898,079`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,996`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDBC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fidelity D&D Bancorp Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$261,224`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,997`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VALU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Value Line Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$226,191`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,998`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NODK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NI Holdings Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$224,359`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,999`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NL Industries Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$196,581`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CIX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompX International Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$192,339`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Similar to our observations regarding the `}<em parentName="p">{`S&P MidCap 400`}</em>{`, we notice that the trading volumes are inconsistent. The stocks leading the `}<em parentName="p">{`Russell 2000`}</em>{` trade at higher volumes than stocks at the top of the `}<em parentName="p">{`S&P SmallCap 600`}</em>{`, even if we exclude AMC as an outlier. As discussed above, this is most likely caused by the different inclusion criteria of the various indices.`}</p>
    <h3>{`Micro Stocks`}</h3>
    <p>{`Micro-cap companies have a market capitalization between $50M and $300M. A representative for these stocks is the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Russell_Microcap_Index"
      }}>{`Russell Microcap Index`}</a>{`, which starts with the 1000 smallest constituents of the `}<em parentName="p">{`Russell 2000`}</em>{` index, which includes ranks 2,001 to 3,000 by market capitalization. From there, the index adds up to 1,000 even smaller stock constituents.`}</p>
    <p>{`We can see that trading volumes easily dip below $100,000 (or even $10,000), which seems prohibitive for any active/ tactical investment approach.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Rank`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WKHS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Workhorse Group Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$204,589,806`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,002`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BBIG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vinco Ventures Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$197,144,250`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,003`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ATER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aterian Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$127,759,018`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,004`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BLNK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blink Charging Co Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$120,952,191`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2,005`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WEBR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Weber Inc Class A Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$103,676,787`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CNNB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cincinnati Bancorp Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$23,832`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GLBZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Glen Burnie Bancorp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$17,516`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ICCH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ICC Holdings Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$17,383`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MAYS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`J W Mays Inc Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$14,311`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WVFC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`W V S Financial Corp Common`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$9,389`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Stock Trading Mechanics and Double Auction`}</h2>
    <p>{`Wherever buyers and sellers trade with each other, they need to agree upon a price. This seemingly innocent event involves the complex process of determining the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Competitive_equilibrium"
      }}>{`competitive equilibrium`}</a>{`. We progress from a simple bilateral trade scenario to a  `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Double_auction"
      }}>{`double auction`}</a>{` with multiple buyers and sellers when trading stocks at an exchange.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/13d20cc23df859ec2d64cb4162d5a410/29114/double-auction.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAzklEQVQoz5WRy27DIBAA/f+/mV6aWAEMyz7ZrRznobqNFM8JDrPsiCmldC1wuS6iFgeZaq2qyjqWLhXXw2F5u9hwIC0gQGbD30pDQ3qY/JKfkIzatXYhMfdw93WSj1AKqsEQJvuXd9iIzqN0uZy+YP4OgTAO9//X3uMWisFNcqqlLWgo7u+aH47f1mvP9e6lw5GtgDRUNf8jKwfBWiW4Fr6m3as3WKx2ERs3WTiMHklHvmpZSktzns85ZyTCD2itpZSYeVLVjojE+DEAsMk/Y+uHbTxlzjMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "matching buy and sell orders in a double auction",
            "title": "matching buy and sell orders in a double auction",
            "src": "/static/13d20cc23df859ec2d64cb4162d5a410/c1b63/double-auction.png",
            "srcSet": ["/static/13d20cc23df859ec2d64cb4162d5a410/5a46d/double-auction.png 300w", "/static/13d20cc23df859ec2d64cb4162d5a410/0a47e/double-auction.png 600w", "/static/13d20cc23df859ec2d64cb4162d5a410/c1b63/double-auction.png 1200w", "/static/13d20cc23df859ec2d64cb4162d5a410/d61c2/double-auction.png 1800w", "/static/13d20cc23df859ec2d64cb4162d5a410/29114/double-auction.png 1920w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In such a double auction, the `}<em parentName="p">{`breakeven price`}</em>{` is of significant relevance. This price is where demand to buy shares matches the volume of shares available for sale, such that the buyer's bid price exceeds the seller's asking price. The chart above illustrates this.`}</p>
    <p>{`When the traded volume is low, the slope of the curves becomes steeper, and the breakeven price sits at a lower volume. Now, if the volume of an investor's order is large enough to have a noticeable impact on the slope of the buy or sell price, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Slippage_(finance)"
      }}>{`slippage`}</a>{` increases, and the order fills at a worse price. It is important to note that the slippage is ultimately not caused by overall low volumes, but by an imbalance between supply and demand. This imbalance leads to actors being paired with counterparties requiring an additional markup/ markdown to make the trade.`}</p>
    <p>{`Therefore, investors typically have a vested interest in finding sufficient trade volumes to make their order appear small and insignificant. But, as we have seen above, this might not be the case when trading small-cap or even micro-cap stocks.`}</p>
    <p>{`This is why our stock-trading strategies, most notably `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-a-stroll/"
      }}>{`Stocks on a Stroll`}</a>{`, trade the `}<em parentName="p">{`S&P 100`}</em>{` universe, which provides excellent liquidity to accommodate active investment styles.`}</p>
    <ButtonPrimary text="Check out Stocks on the Loose" to="/portfolios/tt-stocks-on-the-loose/" mdxType="ButtonPrimary" />
    <br /><br /><br />
    <h2>{`Liquidity in the ETF Market`}</h2>
    <p>{`Similar to stocks, we can also look at the liquidity of ETFs. On the top end, we find ETFs with trading volumes easily rivaling those of the most-traded stocks. But on the bottom end, we find ETFs with liquidity that is about on-par with that of small-cap stocks:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Ticker`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Daily Dollar-Volume`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SPY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SPDR S&P 500 Trust ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$29,898,292,087`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QQQ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invesco QQQ Trust Series 1 ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$13,694,076,718`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TQQQ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares UltraPro QQQ ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$3,909,368,484`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TLT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iShares 20 Plus Year Treasury Bond ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$2,136,721,453`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HYG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`iShares iBoxx $ High Yield Corporate Bond ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$2,014,995,789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VXZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Barclays iPath Series B S&P 500 VIX Mid-Term Futures ETN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$681,538`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares Ultra 7-10 Year Treasury ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$677,811`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TYD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Direxion Daily 7-10 Year Treasury Bull 3X Shares ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$512,071`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MYY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares Short MidCap400 ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$359,705`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SAA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProShares Ultra SmallCap600 ETF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`$266,906`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`However, in the context of ETFs, liquidity has very different implications than for individual stocks. This is because ETFs are open-ended funds, which continuously issue (or redeem shares).`}</p>
    <h2>{`ETF Trading Mechanics and Share Creation/ Redemption`}</h2>
    <p>{`ETFs trade in two different ways. For once, they trade on the secondary market, where buyers and sellers meet at an exchange and swap their inventory. However, when orders cannot be satisfied through inventory on the secondary market (and there is an economic incentive), so-called authorized participants (APs) step in. These APs will trade against their inventory and then create or redeem shares overnight. Through this process, APs provide additional liquidity on demand. Curious investors can read more about this process in this `}<a parentName="p" {...{
        "href": "https://www.blackrock.com/corporate/literature/whitepaper/viewpoint-etf-primary-trading-role-of-authorized-participants-march-2017.pdf"
      }}>{`primer on ETF trading`}</a>{` and this summary of `}<a parentName="p" {...{
        "href": "https://www.etf.com/publications/journalofindexes/joi-articles/8470-etf-creation-and-redemption-models?nopaging=1"
      }}>{`ETF creation and redemption models`}</a>{`.`}</p>
    <p>{`As a consequence of APs stepping in, low trade volumes of ETFs are, compared to stocks, of much less concern. Provided the underlying basket of stocks is sufficiently liquid, we can expect the APs to charge a relatively low risk premium, resulting in the ETFs tracking their underlying indices tightly. As a result, we can expect ETFs to trade much more liquidly than their actual trading volume suggests.`}</p>
    <p>{`Ultimately, the true limitation is the liquidity of the underlying basket of assets, and an ETF may have higher liquidity in the secondary market than the underlying basket of assets. Should an imbalance of supply and demand occur for such an ETF, the APs will not be able to issue or redeem shares fast enough to satisfy the market demand. As a result, APs will be charging higher risk premia to compensate for this delta in liquidity, and a significant tracking error may occur. We believe that the underlying assets of typical market indices, industry sectors, or factor ETFs should be liquid enough to not be at risk for this to happen.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Liquidity matters. When trading stocks, investors need to ensure that their time horizon for holding the security reflects the stock's spreads, which will inevitably widen with lower liquidity. As a result, momentum strategies holding their assets for several weeks or even months can most likely cope with limited liquidity, while mean-reversion strategies with holding periods of a few days probably won't.`}</p>
    <p>{`When trading ETFs, it is essential to realize that trading volume is not an all-encompassing measure of the fund's overall liquidity. Instead, investors should consider the liquidity in the secondary market as much as the liquidity of the underlying securities. In this context, ETFs tracking illiquid securities seem more problematic than ETFs with low trading volume.`}</p>
    <p>{`All of these thoughts go into our suite of `}<strong parentName="p">{`Premium`}</strong>{` portfolios. We take pride in these portfolios and believe that they achieve what matters most to investors: To increase returns and minimize risk.`}</p>
    <ButtonPrimary text="Tour Our Premium Portfolios" to="/portfolios/?tag=premium" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      